import React, {useState, useEffect} from "react";
import {ArrowRightIcon, MoonIcon, SunIcon} from "@heroicons/react/20/solid";
import {useLocation, useNavigate} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";
import Button from "../../components/Button";

const BellsNavBar = ({data, handleThemeChange, settings, isMobileNavBarOpened, setIsMobileNavBarOpen, setShowContactModal}) => {
    const options = data.navbar_options;
    const loginOptions = data.navbar_login_options;
    const combinedOptions = [...loginOptions, ...options]
    const navigate = useNavigate();
    let location = useLocation();
    const [visible, setVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(0);

    const [menuOpen, setMenuOpen] = useState(false);

    const handleButtonClick = (id) => {
        if (menuOpen) {
            setMenuOpen(false);
        }
        if (location.pathname === '/' + id) return
        if (id) {
            navigate('/' + id);
            window.scrollTo(0, 0);
        } else {
            navigate('/');
            window.scrollTo(0, 0);
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
            setPrevScrollPos(currentScrollPos);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [prevScrollPos, visible]);

    useEffect(() => {
        if (isMobileNavBarOpened) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [isMobileNavBarOpened]);

    const mobileNavAnimationVariants = {
        "closed": {
            top: visible ? '20px' : '-120px',
            left: '20px',
        },
        "open": {
            width: '100vw',
            height: '100vh',
            borderRadius: 0,
            top: 0,
            left: 0,
            opacity: 0.98,
            position: 'fixed'
        },
        "test": {
            width: '100vw',
            height: '100vh',
            top: 50,
            position: 'relative'
        }
    }

    const mobileNavIconAnimationVariants = {
        "closed": {
            top: '20px',
            left: '20px'
        },
        "open": {
            top: '-50px',
            left: '20px',
            position: 'relative'
        },
        // "test": {
        //     top: '0px',
        //     left: '0px',
        // }
    }

    const systemStyles = {
        lightLogo: " bg-[url('assets/labyrinthBlue.svg')] ",
        darkLogo: " bg-[url('assets/labyrinth.svg')] ",
        lightText: " bg-[url('assets/creovia.svg')] ",
        darkText: " bg-[url('assets/creoviaWhite.svg')] "
    }

    const openMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const scrollToSection = (section) => {
        setMenuOpen(!menuOpen);
        navigate('/');

        setTimeout(() => {
            const element = document.getElementById(section);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        }, 600)
    }

    return (
            <div
                className={(location.pathname !== '/' ? " text-black dark:text-white " : " text-white ") + " px-16 py-8 hidden lg:flex justify-between absolute items-center w-full z-50 top-0 bg-white dark:bg-[#020617]"}>
                <div className={"flex items-center text-sm font-medium gap-2.5"}>
                    <div className="flex items-center gap-3 mr-3 cursor-pointer"
                         onClick={() => {
                             handleButtonClick()
                         }}
                    >
                        <div
                            className={(location.pathname !== '/' ? " bg-[url('assets/labyrinthBlue.svg')] dark:bg-[url('assets/labyrinth.svg')] " : localStorage.getItem('theme') === 'light' ? systemStyles.lightLogo : systemStyles.darkLogo) + "  h-[28px] w-[28px] bg-no-repeat bg-contain bg-center"}></div>
                        <div
                            className={(location.pathname !== '/' ? " bg-[url('assets/creovia.svg')] dark:bg-[url('assets/creoviaWhite.svg')] " : localStorage.getItem('theme') === 'light' ? systemStyles.lightText : systemStyles.darkText) + "h-[18px] w-[90px] bg-no-repeat bg-contain bg-center"}></div>
                    </div>
                </div>

                <div className="flex text-sm font-medium gap-3 items-center">
                    <button className="flex translate-x-5 justify-center text-neutral-500 first:hover:text-neutral-900" onClick={() => handleThemeChange()}>
                        <MoonIcon className="h-5 w-5 z-10 dark:opacity-0 opacity-100 ease-in duration-500"/>
                        <SunIcon className=" h-5 w-5 z-10 dark:opacity-100 opacity-0 ease-in duration-500 -translate-x-5 text-white hover:text-gray-400"/>
                    </button>
                </div>
            </div>
    )
}

export default BellsNavBar;