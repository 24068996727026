import React from "react";
import { themes, textSize, iconSize} from "./styles";

export const Button = ({children, ...props}) => {

    if (!children) throw new Error("no children were passed into Button component")

    let {className, options} = props;
    delete props.className;
    let config = {
        theme: 'primary',
        disabled: false,
        size: 'sm',
        type: 'button',
        ...options
    }
    const hasMultipleChildren = Array.isArray(children);

    let styles = themes[config.theme][config.disabled ? 'disabled' : 'enabled'];

    return (
        <button type={config.type} disabled={config.disabled} className={styles + " whitespace-nowrap w-fit flex items-center gap-1 ease-in-out duration-300" + textSize[config.size] + className} {...props} >
            {
                hasMultipleChildren ?
                children.map((child, i) => {
                    if (typeof child !== "string") return (<div key={i} className={" aspect-square " + iconSize[config.size]}>{child}</div>);
                    return (
                        <div key={i}>{child}</div>
                    )
                })
                    :
                    typeof children === "string" ?
                        <div className={textSize[config.size]}>{children}</div>
                    :
                        <div className={ " aspect-square " + iconSize[config.size] }>{children}</div>
            }
        </button>
    )
}