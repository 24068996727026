import React from "react";
import JasonPerez from "./assets/JasonPerez.png"
import {motion} from "framer-motion";
import CarouselImages from "./assets/Carousel";
import {Helmet, HelmetData} from "react-helmet-async";

const helmetData = new HelmetData({});


const Company = ({data}) => {

	return (
		<>
			<Helmet helmetData={helmetData}>
				<title>Creovia | Our Team</title>
				<meta name="title" content="Creovia | Our Team"/>
				<meta name="description" content="Our mission is to transform obstacles into innovative solutions, prioritizing customer satisfaction and success above all."/>
				<meta property="twitter:title" content="Creovia | Our Team"/>
				<meta property="twitter:description" content="Our mission is to transform obstacles into innovative solutions, prioritizing customer satisfaction and success above all."/>
				<meta property="twitter:image" content="./assets/images/OG.png"/>
				<meta property="twitter:url" content="https://creovia.io/company"/>
				<meta property="twitter:card" content="summary_large_image"/>
				<meta property="og:site_name" content="Creovia"/>
				<meta property="og:description" content="Our mission is to transform obstacles into innovative solutions, prioritizing customer satisfaction and success above all."/>
				<meta property="og:url" content="https://creovia.io/company"/>
				<meta property="og:image" content="./assets/images/OG.png"/>
				<meta property="og:type" content="website"/>
				<link rel="canonical" href="https://creovia.io/company"/>
			</Helmet>
			<div
				className=" flex sm:bg-light-primary py-40 dark:bg-dark-primary flex-col overflow-hidden justify-center items-center dark:text-light-primary">
				<div
					className={" flex flex-col gap-5 dark:bg-dark-secondary bg-light-secondary pt-12 px-5 sm:px-16  w-full max-w-[1050px] lg:rounded-t-3xl"}>
					<h4 className="text-[16px]">{data.subhead}</h4>
					<h1 className={" text-[34px] font-semibold "}>{data.header}</h1>
					<p>{data.body}</p>
					<div className={"flex items-center gap-5"}>
						<div
							className={" h-[75px] rounded-lg aspect-square bg-contain"}
							style={{backgroundImage: `url('${JasonPerez}')`}}
						>
						</div>
						<div className={"text-neutral-600 dark:text-white"}>
							<h4 className={"font-semibold"}>Jason Perez</h4>
							<p>Co-founder & President</p>
						</div>
					</div>

				<div className={"w-[100%] max-w-[1920px] h-52 overflow-hidden relative mt-10 z-20"}>
					<div
						className={"absolute h-52 bg-gradient-to-r from-light-primary dark:from-dark-primary md:w-6 left-0 z-20"}></div>
					<div
						className={"absolute h-52 bg-gradient-to-l from-light-primary dark:from-dark-primary md:w-6 right-0 z-20"}></div>
					<motion.div
						animate={{left: ['0%', '-100%']}}
						transition={{repeat: Infinity, duration: 20, ease: "linear"}}
						className={"w-[200%] flex items-center h-52 justify-around absolute left-0 gap-5 animate"}>
						{CarouselImages.map((img, i) => {
							return <img
								key={i}
								src={img}
								alt={'creovia team carousel image' + i}
								className={'h-full w-fit shadow'}
							>

							</img>
						})}
						{CarouselImages.map((img, i) => {
							return <img
								src={img}
								key={i}
								alt={'creovia team carousel image' + i}
								className={'h-full w-fit shadow'}
							>

							</img>
						})}
					</motion.div>
				</div>
				</div>

				<div
					className={" flex flex-col gap-5 dark:bg-dark-secondary bg-light-secondary p-5 sm:p-16 w-full max-w-[1050px] lg:rounded-b-3xl"}>
					<h1 className={" text-[34px] font-semibold "}>{data.team.header}</h1>
					<p>{data.team.body}</p>
					<div className={"grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8"}>
						{data.team.employees.map((employee, i) => {
							return (
								<div key={i} className={"text-sm flex flex-col gap-1"}>
									<div
										className={employee.image + " w-[75px] h-[75px] rounded-lg bg-cover "}
									>

									</div>
									<div className={"dark:text-white text-neutral-600 pr-8"}>
										<h4 className={"font-semibold"}>{employee.name}</h4>
										<p>{employee.title}</p>
									</div>
								</div>
							)
						})}

					</div>
				</div>
			</div>
		</>
	)
}

export default Company
