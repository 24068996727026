import React, {useState, useEffect} from "react";
import {Button} from "../../../components/Button/Button"
import {ArrowRightIcon, MoonIcon, SunIcon} from "@heroicons/react/20/solid";
import {useLocation, useNavigate} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";

const NavBar = ({data, handleThemeChange, settings, isMobileNavBarOpened, setIsMobileNavBarOpen, setShowContactModal}) => {
    const options = data.navbar_options;
    const loginOptions = data.navbar_login_options;
    const combinedOptions = [...loginOptions, ...options]
    const navigate = useNavigate();
    let location = useLocation();
    const [visible, setVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(0);

    const [menuOpen, setMenuOpen] = useState(false);

    const handleButtonClick = (id) => {
        if (menuOpen) {
            setMenuOpen(false);
        }
        if (location.pathname === '/' + id) return
        if (id) {
            navigate('/' + id);
            window.scrollTo(0, 0);
        } else {
            navigate('/');
            window.scrollTo(0, 0);
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
            setPrevScrollPos(currentScrollPos);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [prevScrollPos, visible]);

    useEffect(() => {
        if (isMobileNavBarOpened) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [isMobileNavBarOpened]);

    const mobileNavAnimationVariants = {
        "closed": {
            top: visible ? '20px' : '-120px',
            left: '20px',
        },
        "open": {
            width: '100vw',
            height: '100vh',
            borderRadius: 0,
            top: 0,
            left: 0,
            opacity: 0.98,
            position: 'fixed'
        },
        "test": {
            width: '100vw',
            height: '100vh',
            top: 50,
            position: 'relative'
        }
    }

    const mobileNavIconAnimationVariants = {
        "closed": {
            top: '20px',
            left: '20px'
        },
        "open": {
            top: '-50px',
            left: '20px',
            position: 'relative'
        },
        // "test": {
        //     top: '0px',
        //     left: '0px',
        // }
    }

    const systemStyles = {
        lightLogo: " bg-[url('assets/labyrinthBlue.svg')] ",
        darkLogo: " bg-[url('assets/labyrinth.svg')] ",
        lightText: " bg-[url('assets/creovia.svg')] ",
        darkText: " bg-[url('assets/creoviaWhite.svg')] "
    }

    const openMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const scrollToSection = (section) => {
        setMenuOpen(!menuOpen);
        navigate('/');

        setTimeout(() => {
            const element = document.getElementById(section);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        }, 600)
    }

    return (
        <>
            <div className={"flex lg:hidden h-24 fixed z-[51] w-full justify-between items-center bg-white dark:bg-[#020617]"}>
                <div className={"flex items-center text-sm font-medium gap-2.5"}>
                    <div className="flex items-center gap-3 mr-3 cursor-pointer ml-8"
                         onClick={() => {
                             handleButtonClick()
                         }}
                    >
                        <div
                            className={(location.pathname !== '/' ? " bg-[url('assets/labyrinthBlue.svg')] dark:bg-[url('assets/labyrinth.svg')] " : localStorage.getItem('theme') === 'light' ? systemStyles.lightLogo : systemStyles.darkLogo) + "  h-[28px] w-[28px] bg-no-repeat bg-contain bg-center"}></div>
                        {/*<div*/}
                        {/*    className={(location.pathname !== '/' ? " bg-[url('assets/creovia.svg')] dark:bg-[url('assets/creoviaWhite.svg')] " : localStorage.getItem('theme') === 'light' ? systemStyles.lightText : systemStyles.darkText) + "h-[18px] w-[90px] bg-no-repeat bg-contain bg-center"}></div>*/}
                    </div>
                    {/*{options.map((option, key) => {*/}
                    {/*    if (option.label === 'Home') return null*/}
                    {/*        return (*/}
                    {/*            <div key={key} className="w-fit text-neutral-500 hover:text-neutral-900 dark:text-white dark:hover:text-gray-400">*/}
                    {/*                <Button*/}
                    {/*                    onClick={() => {*/}
                    {/*                        handleButtonClick(option.link)*/}
                    {/*                    }}*/}
                    {/*                    options={{*/}
                    {/*                        theme: location.pathname !== '/' ? 'transparent' : 'transparent'*/}
                    {/*                    }}>*/}
                    {/*                    {option.label}*/}
                    {/*                </Button>*/}
                    {/*            </div>*/}
                    {/*        )*/}
                    {/*    }*/}
                    {/*)}*/}
                </div>
                <div className="flex gap-4 pr-8">
                    <button className="flex translate-x-5 justify-center items-center text-neutral-500 first:hover:text-neutral-900" onClick={() => handleThemeChange()}>
                        <MoonIcon className="h-5 w-5 z-10 dark:opacity-0 opacity-100 ease-in duration-500"/>
                        <SunIcon className=" h-5 w-5 z-10 dark:opacity-100 opacity-0 ease-in duration-500 -translate-x-5 text-white hover:text-gray-400"/>
                    </button>
                    <div className="flex flex-col justify-evenly w-8 h-8" onClick={openMenu}>
                        <div className={menuOpen ? "border-t-2 border-black dark:border-white rotate-45 translate-y-2 transition-transform duration-500" : "border-t-2 border-black dark:border-white transition-transform duration-500"}></div>
                        <div className={menuOpen ? "hidden" : "border-b-2 border-black dark:border-white"}></div>
                        <div className={menuOpen ? "border-b-2 border-black dark:border-white rotate-[-45deg] -translate-y-1 transition-transform duration-500" : "border-b-2 border-black dark:border-white transition-transform duration-500"}></div>
                    </div>
                </div>

                {/*<motion.div*/}
                {/*    initial={"closed"}*/}
                {/*    variants={mobileNavAnimationVariants}*/}
                {/*    animate={isMobileNavBarOpened ? "test" : "closed"}*/}

                {/*    className={"bg-light-secondary dark:bg-dark-tertiary h-20 w-20 aspect-square shadow rounded-2xl flex justify-center items-center"}>*/}
                {/*    <motion.div*/}
                {/*        variants={mobileNavIconAnimationVariants}*/}
                {/*        initial={"closed"}*/}
                {/*        animate={isMobileNavBarOpened ? "open" : "closed"}*/}
                {/*        >*/}
                {/*        <svg id="Layer_1" data-name="Layer 1" className={"fill-creovia-blue opacity-80 h-10"}*/}
                {/*             viewBox="0 0 100 100" onClick={() => {*/}
                {/*            setIsMobileNavBarOpen((!isMobileNavBarOpened))*/}
                {/*        }}>*/}
                {/*            <rect className="cls-1 shadow-inner" y="-.08" width="100" height="23.52" rx="11.76"*/}
                {/*                  ry="11.76"/>*/}
                {/*            <rect className="cls-1 shadow-inner" y="38.2" width="100" height="23.52" rx="11.76"*/}
                {/*                  ry="11.76"/>*/}
                {/*            <rect className="cls-1 shadow-inner" y="76.48" width="100" height="23.52" rx="11.76"*/}
                {/*                  ry="11.76"/>*/}
                {/*        </svg>*/}
                {/*    </motion.div>*/}
                {/*    <AnimatePresence>*/}
                {/*        {isMobileNavBarOpened &&*/}

                {/*            <div className={"flex flex-col mt-[120px] ml-[40px] gap-5 text-creovia-blue"}>*/}
                {/*                {combinedOptions.map((option, i) => {*/}
                {/*                    return (*/}
                {/*                        <div key={i}>*/}
                {/*                            {(option.label === 'Log in' || option.label === 'Book a Demo') &&*/}
                {/*                                <motion.div*/}
                {/*                                    initial={{opacity: 0, x: -20}}*/}
                {/*                                    animate={{opacity: 0.8, x: 0}}*/}
                {/*                                    transition={{delay: 0.5 + i * 0.1}}*/}
                {/*                                    onClick={() => {*/}
                {/*                                        if (option.label === 'Book a Demo') setShowContactModal(true);*/}
                {/*                                    }}*/}
                {/*                                    className={"font-semibold cursor-pointer text-4xl flex items-center gap-2"}>*/}

                {/*                                    <a href={option.link} >{option.label}</a>*/}

                {/*                                    <ArrowRightIcon className={"h-9"}/>*/}

                {/*                                </motion.div>*/}
                {/*                            }*/}
                {/*                            {*/}
                {/*                                (option.label !== 'Log in' && option.label !== 'Join our Waitlist') &&*/}
                {/*                                <motion.div*/}
                {/*                                    key={i}*/}
                {/*                                    initial={{opacity: 0, x: -20}}*/}
                {/*                                    animate={{opacity: 0.8, x: 0}}*/}
                {/*                                    transition={{delay: 0.5 + i * 0.1}}*/}
                {/*                                    onClick={() => {*/}
                {/*                                        setIsMobileNavBarOpen(false)*/}
                {/*                                        handleButtonClick(option.link)*/}
                {/*                                    }}*/}
                {/*                                    className={"font-semibold cursor-pointer text-4xl flex items-center gap-2"}>*/}

                {/*                                    <a href={option.link}>{option.label}</a>*/}

                {/*                                </motion.div>*/}
                {/*                            }*/}
                {/*                            {option.label === 'Book a Demo' &&*/}
                {/*                                <motion.div*/}
                {/*                                    key={40}*/}
                {/*                                    initial={{opacity: 0}}*/}
                {/*                                    animate={{opacity: 0.8}}*/}
                {/*                                    transition={{delay: 0.5}}*/}
                {/*                                    className={"h-1 w-5/6 bg-creovia-blue"}></motion.div>*/}
                {/*                            }*/}
                {/*                        </div>*/}
                {/*                    )*/}

                {/*                })*/}
                {/*                }*/}
                {/*            </div>*/}

                {/*        }*/}

                {/*    </AnimatePresence>*/}
                {/*</motion.div>*/}
            </div>
            <div
                className={(location.pathname !== '/' ? " text-black dark:text-white " : " text-white ") + " px-16 py-8 hidden lg:flex justify-between absolute items-center w-full z-50 top-0 bg-white dark:bg-[#020617]"}>
                <div className={"flex items-center text-sm font-medium gap-2.5"}>
                    <div className="flex items-center gap-3 mr-3 cursor-pointer"
                         onClick={() => {
                             handleButtonClick()
                         }}
                    >
                        <div
                            className={(location.pathname !== '/' ? " bg-[url('assets/labyrinthBlue.svg')] dark:bg-[url('assets/labyrinth.svg')] " : localStorage.getItem('theme') === 'light' ? systemStyles.lightLogo : systemStyles.darkLogo) + "  h-[28px] w-[28px] bg-no-repeat bg-contain bg-center"}></div>
                        <div
                            className={(location.pathname !== '/' ? " bg-[url('assets/creovia.svg')] dark:bg-[url('assets/creoviaWhite.svg')] " : localStorage.getItem('theme') === 'light' ? systemStyles.lightText : systemStyles.darkText) + "h-[18px] w-[90px] bg-no-repeat bg-contain bg-center"}></div>
                    </div>
                    {options.map((option, key) => {
                        if (option.label === 'Home') return null
                            return (
                                <div key={key} className="w-fit text-neutral-500 hover:text-neutral-900 dark:text-white dark:hover:text-gray-400">
                                    <Button
                                        onClick={() => {
                                            handleButtonClick(option.link)
                                        }}
                                        options={{
                                            theme: location.pathname !== '/' ? 'inverted' : 'inverted'
                                        }}>
                                        {option.label}
                                    </Button>
                                </div>
                            )
                        }
                    )}
                    <div className={"w-fit text-neutral-500 hover:text-neutral-900 dark:text-white dark:hover:text-gray-400 px-2 cursor-pointer"} onClick={() => scrollToSection('features')}>Features</div>
                    <div className={"w-fit text-neutral-500 hover:text-neutral-900 dark:text-white dark:hover:text-gray-400 px-2 cursor-pointer"} onClick={() => scrollToSection('products')}>Products</div>
                    <div className={"w-fit text-neutral-500 hover:text-neutral-900 dark:text-white dark:hover:text-gray-400 px-2 cursor-pointer"} onClick={() => scrollToSection('testimonials')}>Testimonials</div>
                </div>

                <div className="flex text-sm font-medium gap-3 items-center">
                    <button className="flex translate-x-5 justify-center text-neutral-500 first:hover:text-neutral-900" onClick={() => handleThemeChange()}>
                        <MoonIcon className="h-5 w-5 z-10 dark:opacity-0 opacity-100 ease-in duration-500"/>
                        <SunIcon className=" h-5 w-5 z-10 dark:opacity-100 opacity-0 ease-in duration-500 -translate-x-5 text-white hover:text-gray-400"/>
                    </button>

                    {loginOptions.map((option, key) => {
                            if (option.label === 'Join our wait list') {

                                return (
                                    <div key={key} className="w-fit">

                                        <Button key={key}
                                                className="w-fit text-neutral-500 hover:text-neutral-900 dark:text-white dark:hover:text-gray-400 last:rounded last:hover:bg-blue-700 last:bg-light-buttonFill dark:last:bg-blue-300 dark:last:hover:bg-blue-500 last:text-light-buttonText last:hover:text-light-buttonText dark:last:text-neutral-900 dark:last:hover:text-neutral-900"
                                                options={{
                                                    theme: location.pathname !== '/' ? 'inverted' : 'inverted'
                                                }}
                                                onClick={() => scrollToSection('contact')}
                                        >{option.label}
                                        </Button>
                                    </div>
                                )

                            } else {

                                return (
                                    <a key={key} href={option.link} className="w-fit text-neutral-500 hover:text-neutral-900 dark:text-white dark:hover:text-gray-400 last:rounded last:hover:bg-blue-700 last:bg-light-buttonFill dark:last:bg-blue-300 dark:last:hover:bg-blue-500 last:text-light-buttonText last:hover:text-light-buttonText dark:last:text-neutral-900 dark:last:hover:text-neutral-900">

                                        <Button key={key}
                                                options={{
                                                    theme: location.pathname !== '/' ? 'inverted' : 'inverted'
                                                }}
                                        >{option.label}
                                        </Button>
                                    </a>
                                )
                            }
                        }
                    )}

                </div>
            </div>

            {menuOpen ?
                <div className="lg:hidden fixed z-50 bg-light-primary dark:bg-dark-tertiary h-screen w-screen flex flex-col justify-center gap-6 text-3xl transition-transform duration-1000 text-center dark:text-white">
                    {location.pathname === '/' ? <h2 onClick={() => handleButtonClick(options[0].link)}>Company</h2> : <h2 onClick={() => handleButtonClick()}>Home</h2>}
                    <h2 onClick={() => scrollToSection('features')}>Features</h2>
                    <h2 onClick={() => scrollToSection('products')}>Products</h2>
                    <h2 onClick={() => scrollToSection('testimonials')}>Testimonials</h2>
                    <h2 onClick={() => scrollToSection('contact')}>Contact</h2>
                    <h2 onClick={() => window.location.href = loginOptions[0].link}>Log in</h2>
                </div> :
                <div className="flex flex-col gap-6 text-3xl transition-transform -translate-y-[100vh] duration-500 text-center dark:text-white"></div>}
        </>
    )
}

export default NavBar;
