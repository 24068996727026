export const convertTo12Hour = (time24) => {
  const [hours, minutes] = time24.split(':');
  let hour = parseInt(hours, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12 || 12;
  return `${hour}:${minutes} ${ampm}`;
};

export const getCurrentPeriodAndTimeLeft = (schedule, time) => {
  const currentTimeString = time.toTimeString().slice(0, 5);
  const periods = schedule.periods;

  if (currentTimeString < periods[0].startTime) {
    return { period: null, timeLeft: null, status: 'before-school' };
  }

  if (currentTimeString >= periods[periods.length - 1].endTime) {
    return { period: null, timeLeft: null, status: 'after-school' };
  }

  for (let i = 0; i < periods.length; i++) {
    if (currentTimeString >= periods[i].startTime && currentTimeString < periods[i].endTime) {
      const endTime = new Date(time);
      const [hours, minutes] = periods[i].endTime.split(':');
      endTime.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
      const timeLeft = Math.max(0, Math.floor((endTime - time) / 1000));
      return { period: {...periods[i], startTime: convertTo12Hour(periods[i].startTime), endTime: convertTo12Hour(periods[i].endTime)}, timeLeft, status: 'in-session' };
    } else if (i < periods.length - 1 && currentTimeString >= periods[i].endTime && currentTimeString < periods[i + 1].startTime) {
      const passingPeriod = {
        period: 'Passing',
        startTime: convertTo12Hour(periods[i].endTime),
        endTime: convertTo12Hour(periods[i + 1].startTime),
        duration: Math.floor((new Date(`2000/01/01 ${periods[i + 1].startTime}`) - new Date(`2000/01/01 ${periods[i].endTime}`)) / 60000)
      };
      const endTime = new Date(time);
      const [hours, minutes] = periods[i + 1].startTime.split(':');
      endTime.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
      const timeLeft = Math.max(0, Math.floor((endTime - time) / 1000));
      return { period: passingPeriod, timeLeft, status: 'in-session' };
    }
  }
  return { period: null, timeLeft: null, status: 'error' };
};

export const getTimeUntilSchoolStarts = (schedule, currentTime) => {
  const firstPeriodStart = new Date(currentTime);
  const [hours, minutes] = schedule.periods[0].startTime.split(':');
  firstPeriodStart.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
  
  if (firstPeriodStart <= currentTime) {
    firstPeriodStart.setDate(firstPeriodStart.getDate() + 1);
  }

  return Math.floor((firstPeriodStart - currentTime) / 1000);
};

export const getDisplayPeriods = (schedule) => {
  const displayPeriods = [];
  const periods = schedule.periods;

  for (let i = 0; i < periods.length; i++) {
    displayPeriods.push({
      ...periods[i],
      startTime: convertTo12Hour(periods[i].startTime),
      endTime: convertTo12Hour(periods[i].endTime)
    });
    if (i < periods.length - 1) {
      displayPeriods.push({
        period: 'Passing',
        startTime: convertTo12Hour(periods[i].endTime),
        endTime: convertTo12Hour(periods[i + 1].startTime),
        duration: 'Passing'
      });
    }
  }

  return displayPeriods;
};