import React, {useEffect, useState} from "react";
import {motion, AnimatePresence} from "framer-motion";
import {CheckIcon, UsersIcon, DocumentTextIcon, ChartBarIcon} from "@heroicons/react/20/solid";
import DirectoryImage from "./assets/DirectoryImage.png";
import PerformImage from "./assets/PerformImage.png";
import MagnifyImage from "./assets/MagnifyImage.png";
import DarkDirectoryImage from "./assets/DarkDirectoryImage.png";
import DarkPerformImage from "./assets/DarkPerformImage.png";
import DarkMagnifyImage from "./assets/DarkMagnifyImage.png";

const ProductShowcase = ({product, setProduct, index, theme}) => {
    const [selected, setSelected] = useState(product);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
            setSelected(product)
        }, 500)
    });

    const getImage = (product) => {
        switch (product.title) {
            case 'Connect':
                if (localStorage.getItem('theme') === 'dark') {
                    return DarkDirectoryImage;
                } else {
                    return DirectoryImage;
                }
            case 'Perform':
                if (localStorage.getItem('theme') === 'dark') {
                    return DarkPerformImage;
                } else {
                    return PerformImage;
                }
            case 'Magnify':
                if (localStorage.getItem('theme') === 'dark') {
                    return DarkMagnifyImage;
                } else {
                    return MagnifyImage;
                }
            default:
                return null;
        }
    };

    const selectProduct = () => {
        setProduct(product);
    }

    const getIcon = (product) => {
        switch (product.title) {
            case 'Connect':
                return (
                    <UsersIcon className="inline w-8 p-2 rounded-full bg-blue-200 text-blue-600 dark:bg-blue-800 dark:text-blue-400" />
                )
            case 'Perform':
                return (
                    <DocumentTextIcon className="inline w-8 p-2 rounded-full bg-blue-200 text-blue-600 dark:bg-blue-800 dark:text-blue-400" />
                )
            case 'Magnify':
                return (
                    <ChartBarIcon className="inline w-8 p-2 rounded-full bg-blue-200 text-blue-600 dark:bg-blue-800 dark:text-blue-400" />
                )
            default:
                return null;
        }
    };

    const positionStyle = {
        left: "flex-col items-start gap-1 mb-5 flex w-full max-w-xl",
        right: "flex-col items-start gap-1 mb-5 flex w-full max-w-xl"
    };

    return (
        <AnimatePresence>
            {!isLoading ?
                <motion.div
                    initial={{opacity: 0, y:200}}
                    whileInView={{opacity: 1, y:0, }}
                    exit={{opacity: 0}}
                    transition={{duration: 0.3}}
                    viewport={{
                        once: true
                    }}
                    className="relative flex gap-4 px-4 lg:px-16 2xl:pb-0 2xl:pr-0 lg:dark:bg-[#0F172A] max-w-[1030px] overflow-hidden py-4 cursor-pointer group" onMouseEnter={selectProduct}>
                    <div className="w-2 bg-light-buttonFill invisible group-hover:visible"></div>
                    <div className="lg:flex gap-10 items-center">
                        <div className={index % 2 === 0 ? positionStyle.left : positionStyle.right}>
                            {/*<h4 className="flex gap-4 items-center text-lg text-creovia-lightblue">{getIcon(product)}{product.title}</h4>*/}
                            <h3 className={"font-semibold text-xl"}>{product.header}</h3>
                            <p className="text-left pb-4">{product.body}</p>
                            {product.bullets.map(bullet => {
                                return (
                                    <p key={bullet[0]} className="flex gap-4 items-center text-left"><CheckIcon className="inline max-w-[20px] min-w-[20px] my-auto p-[2px] rounded-full bg-blue-600 text-white dark:bg-blue-400 dark:text-black"/>{bullet}</p>
                                )
                            })}
                        </div>
                        <img alt={product.title + 'Image'} src={getImage(product)} className="lg:hidden" />
                    </div>
                </motion.div>
            :
            <div className={'h-[1000px]'}></div>
            }
        </AnimatePresence>

    )
};

export default ProductShowcase;
