import React, {useState} from "react";
import ProductShowcase from "./ProductShowcase";
import DirectoryImage from './assets/DirectoryImage.png';
import PerformImage from './assets/PerformImage.png';
import MagnifyImage from './assets/MagnifyImage.png';
import {motion} from "framer-motion";
import DarkDirectoryImage from "./assets/DarkDirectoryImage.png";
import DarkPerformImage from "./assets/DarkPerformImage.png";
import DarkMagnifyImage from "./assets/DarkMagnifyImage.png";

const Products = ({data, theme}) => {
    const [product, setProduct] = useState(data.products[0]);
    const getImage = (product) => {
        switch (product.title) {
            case 'Connect':
                if (localStorage.getItem('theme') === 'dark') {
                    return DarkDirectoryImage;
                } else {
                    return DirectoryImage;
                }
            case 'Perform':
                if (localStorage.getItem('theme') === 'dark') {
                    return DarkPerformImage;
                } else {
                    return PerformImage;
                }
            case 'Magnify':
                if (localStorage.getItem('theme') === 'dark') {
                    return DarkMagnifyImage;
                } else {
                    return MagnifyImage;
                }
            default:
                return null;
        }
    };

    return (

        <div className="w-full max-w-[1620px] flex flex-col items-center gap-10 dark:bg-[#0F172A]">
            <div className="flex justify-start items-center flex-col gap-8 max-w-[700px] sm:max-w-[1620px]">
                <motion.div
                    initial={{opacity: 0}}
                    whileInView={{opacity: 1}}
                    transition={{delay: 0.5, type: "tween", duration: 0.5}}
                    viewport={{
                        once: true
                    }}
                    className={"flex flex-col gap-6"}>
                    <h4 className="text-creovia-lightblue text-left">{data.subhead}</h4>
                    <h2 className="text-[34px] font-semibold text-left">{data.head}</h2>
                    <div className="text-[20px] text-left">{data.body}</div>
                </motion.div>
                <div className="h-fit w-full flex items-center">
                    <div>
                        {data.products.map((product, i) => {
                        // if (i % 2 === 0) {
                        //     return (
                        //         <ProductShowcase product={product} index={i} theme={theme} />
                        //     )
                        // } else {
                        //     return <ProductShowcase product={product} index={i} theme={theme} />
                        // }
                        return <ProductShowcase product={product} setProduct={setProduct} index={i} theme={theme} key={product.title}/>
                    })}
                    </div>
                    <img alt={product.title + 'Image'} src={getImage(product)} className="lg:w-1/2 hidden lg:block" />
                </div>
            </div>
        </div>
    )
};

export default Products;