export const schedules = {
    regularDay: {
      name: "Regular Day Schedule",
      periods: [
        { period: 0, startTime: '07:20', endTime: '08:10', duration: 50 },
        { period: 1, startTime: '08:15', endTime: '09:15', duration: 60},
        { period: 2, startTime: '09:18', endTime: '10:15', duration: 58 },
        { period: 3, startTime: '10:18', endTime: '11:15', duration: 58 },
        { period: 'Lunch', startTime: '11:15', endTime: '11:45', duration: 30},
        { period: 4, startTime: '11:48', endTime: '12:33', duration: 45 },
        { period: 5, startTime: '12:36', endTime: '13:20', duration: 44 },
        { period: 6, startTime: '13:23', endTime: '14:07', duration: 44 },
        { period: 7, startTime: '14:10', endTime: '14:55', duration: 45 },
      ]
    },
    // testBeforeSchool: {
    //   name: "Before School Test",
    //   periods: [
    //     { period: 1, startTime: '17:00', endTime: '17:50', duration: 50 },
    //     { period: 2, startTime: '17:55', endTime: '18:45', duration: 50 },
    //     { period: 3, startTime: '18:50', endTime: '19:40', duration: 50 },
    //     { period: 4, startTime: '19:45', endTime: '20:35', duration: 50 },
    //     { period: 'Lunch', startTime: '20:35', endTime: '21:15', duration: 40 },
    //     { period: 5, startTime: '21:20', endTime: '22:10', duration: 50 },
    //     { period: 6, startTime: '22:15', endTime: '23:05', duration: 50 },
    //     { period: 7, startTime: '23:10', endTime: '00:00', duration: 50 }
    //   ]
    // },
    // testDuringSchool: {
    //   name: "During School Test",
    //   periods: [
    //     { period: 1, startTime: '14:00', endTime: '14:50', duration: 50 },
    //     { period: 2, startTime: '14:55', endTime: '15:45', duration: 50 },
    //     { period: 3, startTime: '15:50', endTime: '16:40', duration: 50 },
    //     { period: 4, startTime: '16:45', endTime: '17:35', duration: 50 },
    //     { period: 'Lunch', startTime: '17:35', endTime: '18:15', duration: 40 },
    //     { period: 5, startTime: '18:20', endTime: '19:10', duration: 50 },
    //     { period: 6, startTime: '19:15', endTime: '20:05', duration: 50 },
    //     { period: 7, startTime: '20:10', endTime: '21:00', duration: 50 }
    //   ]
    // },
    // testAfterSchool: {
    //   name: "After School Test",
    //   periods: [
    //     { period: 1, startTime: '08:00', endTime: '08:50', duration: 50 },
    //     { period: 2, startTime: '08:55', endTime: '09:45', duration: 50 },
    //     { period: 3, startTime: '09:50', endTime: '10:40', duration: 50 },
    //     { period: 4, startTime: '10:45', endTime: '11:35', duration: 50 },
    //     { period: 'Lunch', startTime: '11:35', endTime: '12:15', duration: 40 },
    //     { period: 5, startTime: '12:20', endTime: '13:10', duration: 50 },
    //     { period: 6, startTime: '13:15', endTime: '14:05', duration: 50 },
    //     { period: 7, startTime: '14:10', endTime: '15:00', duration: 50 }
    //   ]
    // }
  };