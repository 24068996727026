const themes = {
    'primary': {
       enabled: 'bg-[#004DD8] py-2 px-1 rounded-lg text-white hover:bg-white hover:text-[#004DD8] hover:shadow active:shadow-inner ',
       disabled: 'opacity-50 bg-[#004DD8] py-2 px-1 rounded-lg text-white cursor-not-allowed'
    },
    'outlined': {
        enabled: 'border-[1px] border-gray-300 py-2 px-1 rounded-lg hover:bg-neutral-100 active:bg-white active:shadow-inner dark:text-white dark:hover:text-black ',
        disabled: 'opacity-50 border-[1px] border-gray-300 py-2 px-1 rounded-lg cursor-not-allowed dark:text-white '
    },
    'transparent': {
        enabled: 'py-2 px-1 rounded-lg hover:bg-[#FAFAFA] active:shadow-inner dark:text-white dark:hover:text-black ',
        disabled: 'opacity-30 bg-[#1D1B201F] rounded-lg py-2 px-1 cursor-not-allowed dark:text-white '
    },
    'secondary': {
        enabled: 'bg-[#FAFAFA] py-2 px-1 text-black rounded-lg hover:bg-[#F3F0F4] active:bg[#F6F2F7] active:shadow-inner',
        disabled: 'opacity-50 bg-[#FAFAFA] py-2 px-1 text-black rounded-lg cursor-not-allowed'
    },
    'inverted': {
        enabled: 'py-2 px-2 active:shadow-inner active:bg-[#004DD8] active:text-white',
        disabled: 'opacity-30 text-[#004DD8] bg-[#1D1B201F] py-2 px-1 cursor-not-allowed'
    },
    'red': {
        enabled: 'py-2 px-1 bg-[#FFFFFF] text-[#BA1A1A] rounded-lg hover:bg-[#BA1A1A] hover:text-white active:bg-[#410002] active:text-[#FFDAD6] active:shadow-inner ',
        disabled: 'text-[#410002] bg-[#FFDAD6] rounded-lg py-2 px-1 cursor-not-allowed'
    }
}

const textSize = {
    sm: " text-sm ",
    base: " text-base ",
    lg: " text-lg "
}

const iconSize = {
    sm: " h-[16px] ",
    base: " h-[20px] ",
    lg: " h-[24px] "
}

export { themes, textSize, iconSize};