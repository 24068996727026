import React, { useState, useEffect } from 'react';
import { schedules } from './schedules';
import { getCurrentPeriodAndTimeLeft, getDisplayPeriods, getTimeUntilSchoolStarts, convertTo12Hour } from './bellUtils';

export const Bells = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [selectedSchedule, setSelectedSchedule] = useState(Object.keys(schedules)[0] || 'default');
  const [currentPeriod, setCurrentPeriod] = useState(null);
  const [timeUntilEnd, setTimeUntilEnd] = useState(null);
  const [schoolStatus, setSchoolStatus] = useState('in-session');

  useEffect(() => {
        // Create the meta tag
        const metaTag = document.createElement('meta');
        metaTag.name = 'robots';
        metaTag.content = 'noindex';

        // Append the meta tag to the head
        document.head.appendChild(metaTag);

        // Cleanup function to remove the meta tag when the component unmounts
        return () => {
            document.head.removeChild(metaTag);
        };
    }, []);

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (schedules[selectedSchedule]) {
      const { period, timeLeft, status } = getCurrentPeriodAndTimeLeft(schedules[selectedSchedule], currentTime);
      setCurrentPeriod(period);
      setTimeUntilEnd(timeLeft);
      setSchoolStatus(status);
    }
  }, [currentTime, selectedSchedule]);

  const handleScheduleChange = (e) => {
    setSelectedSchedule(e.target.value);
  };

  if (!schedules[selectedSchedule]) {
    return <div>Error: Selected schedule not found</div>;
  }

  const displayPeriods = getDisplayPeriods(schedules[selectedSchedule]);

  const formatTimeLeft = (seconds) => {
    if (seconds === null) return 'N/A';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return hours > 0 
      ? `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
      : `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const isPassingPeriod = currentPeriod && currentPeriod.period === 'Passing';
  const progressPercentage = isPassingPeriod && timeUntilEnd !== null
    ? (1 - timeUntilEnd / (currentPeriod.duration * 60)) * 100
    : 0;

  const renderStatusBar = () => {
    switch (schoolStatus) {
      case 'before-school':
        const timeUntilStart = getTimeUntilSchoolStarts(schedules[selectedSchedule], currentTime);
        return (
          <div className="text-center">
            <p className="text-xl font-bold text-indigo-600 dark:text-indigo-400 mb-2">
              School starts in: {formatTimeLeft(timeUntilStart)}
            </p>
          </div>
        );
      case 'after-school':
        return (
          <div className="text-center">
            <p className="text-xl font-bold text-indigo-600 dark:text-indigo-400">
              Have a good day!
            </p>
          </div>
        );
      default:
        return (
          <>
            <div className="flex justify-between items-center mb-2">
              <div className="text-lg font-medium text-indigo-800 dark:text-indigo-200">
                Current Time: {convertTo12Hour(currentTime.toTimeString().slice(0, 5))}
              </div>
              <div className="text-xl font-bold text-indigo-600 dark:text-indigo-400">
                Time Left: {formatTimeLeft(timeUntilEnd)}
              </div>
            </div>
            {isPassingPeriod && (
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div 
                  className="bg-red-600 h-2.5 rounded-full transition-all duration-1000 ease-linear"
                  style={{ width: `${progressPercentage}%` }}
                ></div>
              </div>
            )}
          </>
        );
    }
  };

  return (
    <div className="min-h-screen lg:pt-32 bg-gradient-to-br from-blue-100 to-indigo-200 dark:from-gray-800 dark:to-indigo-900 p-8">
      <div className="max-w-4xl mx-auto bg-white dark:bg-gray-800 rounded-xl shadow-2xl overflow-hidden">
        <div className="p-8">
          <h1 className="text-3xl font-bold text-center text-indigo-600 dark:text-indigo-400 mb-6">
            School Bells
          </h1>
          
          <div className="mb-6">
            <select
              className="w-full p-3 text-lg bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-200 ease-in-out"
              value={selectedSchedule}
              onChange={handleScheduleChange}
            >
              {Object.entries(schedules).map(([key, schedule]) => (
                <option key={key} value={key}>{schedule.name}</option>
              ))}
            </select>
          </div>

          <h2 className="text-2xl font-semibold text-center text-gray-800 dark:text-gray-200 mb-6">
            {schedules[selectedSchedule].name}
          </h2>
          
          <div className="mb-8 p-4 bg-indigo-200 dark:bg-indigo-900 rounded-lg">
            {renderStatusBar()}
          </div>
          
          <div className="mt-8 overflow-x-auto">
            <table className="w-full text-left border-collapse">
              <thead>
                <tr className="bg-indigo-200 dark:bg-indigo-800">
                  <th className="p-3 font-semibold text-indigo-800 dark:text-indigo-200">Period</th>
                  <th className="p-3 font-semibold text-indigo-800 dark:text-indigo-200">Start Time</th>
                  <th className="p-3 font-semibold text-indigo-800 dark:text-indigo-200">End Time</th>
                  <th className="p-3 font-semibold text-indigo-800 dark:text-indigo-200">Duration</th>
                </tr>
              </thead>
              <tbody>
                {displayPeriods.map((period, index) => (
                  <tr 
                    key={index} 
                    className={`
                      ${index % 2 === 0 ? 'bg-gray-50 dark:bg-gray-700' : 'bg-white dark:bg-gray-800'}
                      ${currentPeriod && currentPeriod.startTime === period.startTime && currentPeriod.endTime === period.endTime ? 'bg-yellow-200 dark:bg-yellow-900' : ''}
                    `}
                  >
                    <td className="p-3 border-t dark:text-indigo-200 border-gray-200 dark:border-gray-700">{period.period}</td>
                    <td className="p-3 border-t dark:text-indigo-200 border-gray-200 dark:border-gray-700">{period.startTime}</td>
                    <td className="p-3 border-t dark:text-indigo-200 border-gray-200 dark:border-gray-700">{period.endTime}</td>
                    <td className="p-3 border-t dark:text-indigo-200 border-gray-200 dark:border-gray-700">{period.duration === 'Passing' ? 'Passing' : `${period.duration} minutes`}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};