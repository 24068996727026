import ChefJason from "./ChefJason.png"
import Team01 from "./Team01.png"
import CreoviaSign from "./CreoviaSign.png"
import Team02 from "./Team02.png"
import Team03 from "./Team03.png"
import Team04 from "./Team04.png"
import Team05 from "./Team05.jpg"
import Team06 from "./Team06.jpg"

const Images = [ChefJason, Team01, CreoviaSign, Team02, Team03, Team04, Team05, Team06]
export default Images;